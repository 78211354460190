import React from "react";
import FAQ from "../components/FAQ/Faq";
const Faq = () => {
  return (
    <div>
      <FAQ />
    </div>
  );
};

export default Faq;
