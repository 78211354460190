import React from "react";
import TermsAndConditions from "../components/PrivacyPolicy/TermsAndConditions";
function TermsConditions() {
  return (
    <div>
      <TermsAndConditions />
    </div>
  );
}

export default TermsConditions;
