export const testimonials = [
  {
    id: 1,
    user: "User 1",
    text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
  },
  {
    id: 2,
    user: "User 2",
    text: "Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",
  },
  {
    id: 3,
    user: "User 3",
    text: "When an unknown printer took a galley of type and scrambled it to make a type specimen book.",
  },
  {
    id: 4,
    user: "User 4",
    text: "It has survived not only five centuries, but also the leap into electronic typesetting.",
  },
];
