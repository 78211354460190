import React from "react";
import AboutUs from "../components/About-Us/AboutUs";
function AboutPage() {
  return (
    <div>
      <AboutUs />
    </div>
  );
}

export default AboutPage;
